import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import useClearSelections from '../../hooks/use-clear-filters-selections';
import DestinationSalesPerformance from '../../components/destination-sales-performance/destination-sales-performance';
import PaymentBreakdowns from '../../components/payment-breakdowns/payment-breakdowns';

export function Destination() {
  const { t } = useTranslation();
  const {
    filterUnitTypes,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    unitFilterLoader,
  } = useContext(FILTER_CONTEXT);

  const { startDateArg, endDateArg } = useAnalyticsFilters();
  const { destinationSlug, destinationId } = useParams();
  const { selectedDuration } = useAnalyticsPeriodFilter();

  useClearSelections();

  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {' '}
          {t('destination')}
          <b>
            {' / '} {destinationSlug}
          </b>
        </h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterUnitTypes={filterUnitTypes}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
          unitsLoader={unitFilterLoader}
        />
        <DestinationSalesPerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedUnitTypes={selectedUnitTypes}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
        <PaymentBreakdowns
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedUnitTypes={selectedUnitTypes}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDestinationsIds={destinationId ? [destinationId] : []}
          selectedDestinations={
            destinationId
              ? [{ label: destinationSlug ?? '', value: destinationId }]
              : []
          }
        />
      </div>
    </div>
  );
}

export default Destination;
