import { useTranslation } from 'react-i18next';

export function Engagement() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('engagement')}</h1>
    </div>
  );
}

export default Engagement;
