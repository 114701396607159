import { useTranslation } from 'react-i18next';
import LineChart from '../line-chart/line-chart';
import { useEffect, useState } from 'react';
import { Engagement as EngagementUtils } from '../../utils/engagement';
import { errorMessagesHandler } from '@orascom/utils';
import { LoginsOverTime as LoginsOverTimeType } from '@orascom/broker-sales-man-common-components';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';
import { GraphProps } from '../../definitions/analytics';

export function LoginsOverTime(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [loginsData, setLoginsData] = useState<LoginsOverTimeType>();
  const [loading, setLoading] = useState(true);

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');
  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    EngagementUtils.getAnalyticsLoginsOverTime(params)
      .then((res) => {
        setLoginsData(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  const loginsMetricsData = [
    {
      label: t('totalLoginSessions'),
      value: loginsData?.total_logins.toString(),
    },
    {
      label: t('avgDailyLogins'),
      value: loginsData?.average_daily_logins.toString(),
    },
    {
      label: t('avgWeeklyLogins'),
      value: loginsData?.average_weekly_logins.toString(),
    },
    {
      label: t('avgMontlyLogins'),
      value: loginsData?.average_monthly_logins.toString(),
    },
  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <LineChart
      description={t('loginsOverTimeDescription')}
      title={t('loginsOverTime')}
      res={loginsData}
      selectedDuration={props.selectedDuration ?? 'today'}
      unMaintainAspectRatio
      metricsData={loginsMetricsData}
      tooltipTitle="Sessions"
    />
  );
}

export default LoginsOverTime;
