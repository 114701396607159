import { Route } from '@orascom/api-interfaces';
import Login from '../pages/login/login';
import { SetPassword } from '../pages/set-password/set-password';
import ForgetPassword from '../pages/forget-password/forget-password';
import NotFoundPage from '../pages/not-found/not-found';
import Destination from '../pages/destination/destination';
import Overview from '../pages/overview/overview';
import Updates from '../pages/updates/updates';
import Engagement from '../pages/engagement/engagement';
import Services from '../pages/services/services';
import SalesPerformance from '../pages/sales-performance/sales-performance';
import CustomerBehavior from '../pages/customer-behaviour/customer-behavior';
import LeadInsights from '../pages/lead-insights/lead-insights';

export const ROUTES: Record<string, Route> = {
  Overview: {
    path: '/',
    component: Overview,
    public: false,
  },
  Login: {
    path: '/login',
    component: Login,
    public: true,
  },
  ForgetPassword: {
    path: '/forget-password',
    component: ForgetPassword,
    public: true,
  },
  ResetPassword: {
    path: '/set-password',
    component: SetPassword,
    public: true,
  },
  Destination: {
    path: '/destination/:destinationSlug/:destinationId',
    getPath: (destinationSlug: string, destinationId: number) =>
      `/destination/${destinationSlug}/${destinationId}`,
    component: Destination,
    public: false,
  },
  Services: {
    path: '/services',
    component: Services,
    public: true,
  },
  SalesPerformance: {
    path: '/sales-performance',
    component: SalesPerformance,
    public: false,
  },
  LeadInsights: {
    path: '/lead-insights',
    component: LeadInsights,
    public: false,
  },
  FunnelAnalysis: {
    path: '/funnel-analysis',
    component: SalesPerformance,
    public: false,
  },
  CustomerBehvaiour: {
    path: '/customer-behavior',
    component: CustomerBehavior,
    public: false,
  },
  Demographics: {
    path: '/demographics',
    component: Engagement,
    public: false,
  },
  Notifications: {
    path: '/notifications',
    component: Updates,
    public: false,
  },
  /**
   * Always keep this route at the end of the routes object.
   */
  NotFound: {
    path: '*',
    component: NotFoundPage,
    public: true,
  },
};
