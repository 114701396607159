import { useTranslation } from 'react-i18next';
import LineChart from '../line-chart/line-chart';

export function ResaleRequestsVsSales() {
  const { t } = useTranslation();

  return (
    <LineChart
      description={t('noOfSalesDescription')}
      title={t('noOfResaleVsSales')}
      unMaintainAspectRatio
      selectedDuration={'yesterday'}
    />
  );
}

export default ResaleRequestsVsSales;
