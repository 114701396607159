import { useTranslation } from 'react-i18next';
import LineChart from '../line-chart/line-chart';
import { useEffect, useState } from 'react';
import { Performance as PerformancesUtils } from '../../utils/performace';
import { errorMessagesHandler } from '@orascom/utils';
import { SalesPerformanceBreakdown as SalesPerformanceBreakdownType } from '@orascom/broker-sales-man-common-components';
import {
  GraphProps,
  OverviewUserPurchaseSummary,
} from '../../definitions/analytics';
import Numeral from 'numeral';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';

export function SalesPerformanceBreakdown(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [salesPerformance, setSalesPerformance] =
    useState<SalesPerformanceBreakdownType>();
  const [
    analyticsOverviewUserPurchaseSummary,
    setAnalyticsOverviewUserPurchaseSummary,
  ] = useState<OverviewUserPurchaseSummary>();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    Promise.all([
      PerformancesUtils.getAnalyticsSalesPerformance(params),
      PerformancesUtils.getAnalyticsOverviewUserPurchaseSummary(params),
    ])
      .then((res) => {
        setSalesPerformance(res[0]);
        setAnalyticsOverviewUserPurchaseSummary(res[1]);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  const salesMetricsData = [
    {
      label: t('totalSoldUnits'),
      value: salesPerformance?.sales_count.toString(),
    },
    {
      label: t('totalUnitsRevenue'),
      value: salesPerformance?.sales_revenues
        ? `${Number(
            salesPerformance.sales_revenues.sales_revenues
          ).toLocaleString()} ${salesPerformance.sales_revenues.currency}`
        : '',
    },
    {
      label: t('purchaseConversionRate'),
      value: `${Numeral(
        analyticsOverviewUserPurchaseSummary?.users_purchase_rate
      ).format('0,0.000')}%`,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <LineChart
      description={t('salesPerformanceDescription')}
      title={t('salesPerformance')}
      res={salesPerformance}
      selectedDuration={props.selectedDuration ?? 'today'}
      unMaintainAspectRatio
      metricsData={salesMetricsData}
      tooltipTitle="Units"
    />
  );
}

export default SalesPerformanceBreakdown;
