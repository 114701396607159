import { useTranslation } from 'react-i18next';
import styles from './payment-breakdowns.module.scss';
import PieChartCard from '../pie-chart-card/pie-chart-card';
import { useEffect, useState } from 'react';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { errorMessagesHandler } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import {
  CurrencyBreakdown,
  PaymentBreakdown,
} from '../../definitions/interfaces/performance';
import { useSearchParams } from 'react-router-dom';
import { GraphProps } from '../../definitions/analytics';

export function PaymentBreakdowns(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [paymentBreakdown, setPaymentBreakdown] = useState<PaymentBreakdown>();
  const [currencyBreakdown, setCurrencyBreakdown] =
    useState<CurrencyBreakdown>();

  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');
  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    Promise.all([
      PerformanceUtils.getSalesPerformancePaymentBreakdown(params),
      PerformanceUtils.getSalesPerformanceCurrencyBreakdown(params),
    ])
      .then((res) => {
        setPaymentBreakdown(res[0]);
        setCurrencyBreakdown(res[1]);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  if (loading) {
    return <Loader />;
  }

  const chartColors = [
    '#003970',
    '#4C759B',
    '#D9E2EA',
    '#76c59d',
    '#1c5d3b',
    '#ed6f6f',
    '#a53535',
  ];

  const paymentMethodsChartData =
    paymentBreakdown?.sales_percentage_per_payment_method.map(
      (breakdown, index) => ({
        title: breakdown.payment_method ?? 'unknown method',
        value: breakdown.sales_count,
        key: breakdown.sales_count_percent,
        color: chartColors[index],
      })
    );

  const currencyBreakdownChartData =
    currencyBreakdown?.sales_percentage_per_currency.map(
      (breakdown, index) => ({
        title: breakdown.currency ?? 'unknown method',
        value: breakdown.sales_count,
        key: breakdown.sales_count_percent,
        color: chartColors[index],
      })
    );

  return (
    <div className={styles['wrapper']}>
      <h3 className="shoppes-analytics__sub-title">
        {t('paymentBreakdownsSubTitle')}
      </h3>
      <h2 className="shoppes-analytics__title">
        {t('paymentBreakdownsTitle')}
      </h2>
      <div className={styles['content']}>
        {paymentMethodsChartData?.length ? (
          <PieChartCard
            cardTitle={t('paymentMethodsBreakdown')}
            chartData={paymentMethodsChartData}
          />
        ) : (
          <p>No payment methods breakdown for selected filters</p>
        )}
        {currencyBreakdownChartData?.length ? (
          <PieChartCard
            cardTitle={t('currencyBreakdown')}
            chartData={currencyBreakdownChartData}
          />
        ) : (
          <p>No currency breakdown for selected filters</p>
        )}
      </div>
    </div>
  );
}

export default PaymentBreakdowns;
