import { parseError } from '@orascom/utils';
import { Analytics as AnalyticsApi } from '../api/analytics';
import {
  DestinationViews,
  OverviewSummary,
  PerformanceGraphs,
} from '../definitions/analytics';
import { FilterParams, FilterValue } from '@orascom/api-interfaces';

export class Analytics {
  public static getAnalyticsUnitPerformance(
    params: FilterParams
  ): Promise<PerformanceGraphs> {
    return AnalyticsApi.getAnalyticsUnitPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsDestinationPerformance(
    params: FilterParams
  ): Promise<PerformanceGraphs> {
    return AnalyticsApi.getAnalyticsDestinationPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsDestinationViews(
    params: FilterParams
  ): Promise<DestinationViews> {
    return AnalyticsApi.getAnalyticsDestinationViews(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsOverviewSummary(
    params: FilterParams
  ): Promise<OverviewSummary> {
    return AnalyticsApi.getAnalyticsOverviewSummary(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsFilterDestinations(
    params: FilterParams
  ): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterDestinations(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsFilterUnitTypes(
    params: FilterParams
  ): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterUnitTypes(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}
