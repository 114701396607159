import { parseError } from '@orascom/utils';
import { Performance as PerformanceApi } from '../api/performance';
import { FilterParams } from '@orascom/api-interfaces';
import {
  CurrencyBreakdown,
  PaymentBreakdown,
} from '../definitions/interfaces/performance';
import {
  LeadPerformance,
  SalesPerformanceBreakdown,
} from '@orascom/broker-sales-man-common-components';
import {
  DirectVsIndirectSales,
  LeadsPerDestination,
  OverviewUserInterestSummary,
  OverviewUserPurchaseSummary,
  SalesVsLeads,
  UnitSalesRevenue,
} from '../definitions/analytics';

export class Performance {
  public static getSalesPerformancePaymentBreakdown(
    params: FilterParams
  ): Promise<PaymentBreakdown> {
    return PerformanceApi.getSalesPerformancePaymentBreakdown(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getSalesPerformanceCurrencyBreakdown(
    params: FilterParams
  ): Promise<CurrencyBreakdown> {
    return PerformanceApi.getSalesPerformanceCurrencyBreakdown(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsLeadsPerformance(
    params: FilterParams
  ): Promise<LeadPerformance> {
    return PerformanceApi.getAnalyticsLeadsPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsOverviewUserInterestSummary(
    params: FilterParams
  ): Promise<OverviewUserInterestSummary> {
    return PerformanceApi.getAnalyticsOverviewUserInterestSummary(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsLeadsPerDestination(
    params: FilterParams
  ): Promise<LeadsPerDestination> {
    return PerformanceApi.getAnalyticsLeadsPerDestination(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getSalesCountPerDestination(
    params: FilterParams
  ): Promise<SalesVsLeads> {
    return PerformanceApi.getSalesCountPerDestination(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsSalesPerformance(
    params: FilterParams
  ): Promise<SalesPerformanceBreakdown> {
    return PerformanceApi.getAnalyticsSalesPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsOverviewUserPurchaseSummary(
    params: FilterParams
  ): Promise<OverviewUserPurchaseSummary> {
    return PerformanceApi.getAnalyticsOverviewUserPurchaseSummary(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsUnitSalesRevenue(
    params: FilterParams
  ): Promise<UnitSalesRevenue> {
    return PerformanceApi.getAnalyticsUnitSalesRevenue(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsDirectVsIndirectSales(
    params: FilterParams
  ): Promise<DirectVsIndirectSales> {
    return PerformanceApi.getAnalyticsDirectVsIndirectSales(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsUserRequests(
    params: FilterParams
  ): Promise<number> {
    return PerformanceApi.getAnalyticsUserRequests(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}
