import { useTranslation } from 'react-i18next';
import LineChart from '../line-chart/line-chart';
import { useEffect, useState } from 'react';
import { errorMessagesHandler } from '@orascom/utils';
import Numeral from 'numeral';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';
import { GraphProps } from '../../definitions/analytics';

export function DestinationSalesPerformance(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp, props.selectedUnitTypes]);

  const salesMetricsData = [
    {
      label: t('totalSoldUnits'),
      value: '20',
    },
    {
      label: t('totalUnitsRevenue'),
      value: '10',
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <LineChart
      description={t('salesPerformanceDestinationDescription')}
      title={t('salesPerformance')}
      res={{ name: '', daily_counts_breakdown: [] }}
      selectedDuration={props.selectedDuration ?? 'today'}
      metricsData={salesMetricsData}
      unMaintainAspectRatio
      tooltipTitle="Units"
    />
  );
}

export default DestinationSalesPerformance;
