import styles from './units-resale-overview.module.scss';
import conversionRateIcon from '../../assets/icons/conversion-rate.svg';
import totalUnitsSoldIcon from '../../assets/icons/total-units-sold.svg';
import totalUnitsRevenueIcon from '../../assets/icons/units-revenue.svg';
import SummaryCard from '../summary-card/summary-card';
import { useTranslation } from 'react-i18next';

export function UnitsResaleOverview() {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <h3 className="shoppes-analytics__sub-title">{t('resaleSubTitle')}</h3>
      <h2 className="shoppes-analytics__title">{t('resaleTitle')}</h2>

      <div className="cards__wrapper cards__wrapper--mini">
        <SummaryCard
          cardIconSrc={totalUnitsSoldIcon}
          cardTitle={t('totalResaleRequests')}
          cardDesc="305"
        />
        <SummaryCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('closedResales')}
          cardDesc="78"
        />
        <SummaryCard
          cardIconSrc={conversionRateIcon}
          cardTitle={t('resaleConversionRate')}
          cardDesc="25.58%"
        />
        <SummaryCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('resaleSalesRevenue')}
          cardDesc="792,621,369 EGP"
        />
      </div>
    </div>
  );
}

export default UnitsResaleOverview;
