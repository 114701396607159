/**
 * @author Salma Hefnawy
 * @date 2024-08-19
 * @description implementation of orascom-shopper-analytics-app endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */
const endpointsPrefix =
  process.env['NX__SHOPPER_ANALYTICS_ENDPOINT_PREFIX'] ?? '';

export const ENDPOINTS = {
  login: {
    url: `${endpointsPrefix}/login`,
    method: 'POST',
  },

  logout: {
    url: `${endpointsPrefix}/logout`,
    method: 'POST',
  },

  getUserData: {
    url: `${endpointsPrefix}/me`,
    method: 'GET',
  },

  setPassword: {
    url: `${endpointsPrefix}/reset-password`,
    method: 'POST',
  },

  forgetPassword: {
    url: `${endpointsPrefix}/forgot-password`,
    method: 'POST',
  },
  getAnalyticsUnitPerformance: {
    url: `${endpointsPrefix}/analytics/unit-types-performance`,
    method: 'GET',
  },
  getAnalyticsDestinationPerformance: {
    url: `${endpointsPrefix}/analytics/destinations-performance`,
    method: 'GET',
  },
  getAnalyticsOverviewSummary: {
    url: `${endpointsPrefix}/analytics/overview-summary`,
    method: 'GET',
  },
  getAnalyticsOverviewUserPurchaseSummary: {
    url: `${endpointsPrefix}/analytics/overview-summary/users-purchase`,
    method: 'GET',
  },
  getAnalyticsOverviewUserInterestSummary: {
    url: `${endpointsPrefix}/analytics/overview-summary/users-interest-conversion`,
    method: 'GET',
  },
  getAnalyticsFilterDestinations: {
    url: `${endpointsPrefix}/analytics/filters/destinations`,
    method: 'GET',
  },
  getAnalyticsFilterUnitTypes: {
    url: `${endpointsPrefix}/analytics/filters/unit-types`,
    method: 'GET',
  },
  getUnitServicesRequestsByType: {
    url: `${endpointsPrefix}/analytics/unit-services/count-per-service-type`,
    method: 'GET',
  },
  getAnalyticsDestinationViews: {
    url: `${endpointsPrefix}/analytics/destinations-ggl-views`,
    method: 'GET',
  },
  getAnalyticsLoginsOverTime: {
    url: `${endpointsPrefix}/analytics/logins/sessions-breakdown`,
    method: 'GET',
  },
  getAnalyticsSalesPerformance: {
    url: `${endpointsPrefix}/analytics/sales/performance-breakdown`,
    method: 'GET',
  },
  getAnalyticsUnitSalesRevenue: {
    url: `${endpointsPrefix}/analytics/sales/top-performers`,
    method: 'GET',
  },
  getAnalyticsLeadsPerformance: {
    url: `${endpointsPrefix}/analytics/leads/performance`,
    method: 'GET',
  },
  getAnalyticsLeadsPerDestination: {
    url: `${endpointsPrefix}/analytics/leads/count-per-destinations`,
    method: 'GET',
  },
  getSalesPerformancePaymentBreakdown: {
    url: `${endpointsPrefix}/analytics/sales/payment-breakdown`,
    method: 'GET',
  },
  getSalesPerformanceCurrencyBreakdown: {
    url: `${endpointsPrefix}/analytics/sales/currency-breakdown`,
    method: 'GET',
  },
  getSalesCountPerDestination: {
    url: `${endpointsPrefix}/analytics/leads/sales-count-per-destinations`,
    method: 'GET',
  },
  getAnalyticsDirectVsIndirectSales: {
    url: `${endpointsPrefix}/analytics/sales/direct-vs-indirect-purchase`,
    method: 'GET',
  },
  getAnalyticsUserRequests: {
    url: `${endpointsPrefix}/analytics/user-requests/count`,
    method: 'GET',
  },
};
