import { useEffect, useContext } from 'react';
import { FILTER_CONTEXT } from '../contexts/filters-context';

function useClearSelections() {
  const { handleSelectedDestinations, handleSelectedUnitTypes } =
    useContext(FILTER_CONTEXT);

  useEffect(() => {
    if (handleSelectedDestinations) {
      handleSelectedDestinations([]);
    }
    if (handleSelectedUnitTypes) {
      handleSelectedUnitTypes([]);
    }
  }, []);
}

export default useClearSelections;
