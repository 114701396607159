import {
  getChartData,
  LineGraphData,
  MonthsNames,
} from '@orascom/broker-sales-man-common-components';
import styles from './line-chart.module.scss';
import {
  Chart,
  Title,
  Legend,
  Tooltip,
  PointElement,
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  ChartData,
  TooltipItem,
} from 'chart.js';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { PeriodType } from '@orascom/api-interfaces';

// Register the plugins to the chart
Chart.register(
  LineController,
  LineElement,
  Title,
  Legend,
  Tooltip,
  PointElement,
  CategoryScale,
  LinearScale
);

export interface LineChartProps {
  description: string;
  title: string;
  dim?: boolean;
  activeTabs?: string[];
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<string | undefined>>;
  unMaintainAspectRatio?: boolean;
  selectedDuration: PeriodType;
  res?: LineGraphData;
  metricsData?: { label?: string; value?: string }[];
  tooltipTitle?: string;
}

export function LineChart(props: Readonly<LineChartProps>) {
  const [axis, setAxis] = useState<{
    xAxisLabels: string[];
    yAxisValues: number[];
    label: string;
  }>();

  useEffect(() => {
    if (props.res) {
      const allAxis = getChartData(props.selectedDuration, props.res);
      setAxis(allAxis);
    }
  }, [props.res]);
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: { grid: { display: false }, offset: true },
      y: {
        grid: { display: true },
        ticks: {
          stepSize: 1,
        },
        min: 0,
      },
    },

    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltip: TooltipItem<'line'>) => {
            return `${tooltip.raw} ${props.tooltipTitle}`;
          },
        },
      },
      legend: {
        position: 'bottom' as const,
        align: 'start' as const,

        labels: {
          boxWidth: 18,
          boxHeight: 18,
          borderRadius: 9,
          useBorderRadius: true,
          padding: 30,
        },
      },
    },
  };
  const dataSets = axis
    ? [
        {
          data: axis.yAxisValues,
          borderColor: '#003A70',
          backgroundColor: '#003A70',
          borderWidth: 1.5,
          pointBackgroundColor: '#003A70',
          label: `${axis.label}`,
          parsing: {
            xAxisKey: 'period',
            yAxisKey: `${axis.label} sold`,
          },
          tension: 0.3,
        },
      ]
    : [];

  const data: ChartData<'line', number[], string> = {
    labels: axis ? axis.xAxisLabels : MonthsNames,
    datasets: dataSets,
  };
  return (
    <div className={styles['wrapper']}>
      <p className="shoppes-analytics__sub-title">{props.description}</p>
      <h2 className="shoppes-analytics__title">{props.title}</h2>
      <div
        className={`card ${styles['chart']}  ${
          props.dim ? styles['dim-card'] : ''
        }`}
      >
        <div className={styles['details']}>
          {props.metricsData?.map((metric) => (
            <div key={metric.label} className={styles['metric']}>
              <p>{metric.label}</p>
              <p className={styles['value']}>{metric.value}</p>
            </div>
          ))}
        </div>
        {props.activeTabs && props.setActiveTab && (
          <div className={styles['tabs']}>
            {props.activeTabs.map((tab) => (
              <button
                className={
                  props.activeTab === tab ? styles['active-tab'] : styles['tab']
                }
                key={tab}
                onClick={() => props.setActiveTab?.(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        )}
        <div
          className={`${styles['line-chart__container']} ${
            props.unMaintainAspectRatio
              ? styles['line-chart__container--height']
              : ''
          }`}
        >
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default LineChart;
